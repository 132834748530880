const port = process.env.PORT || '8000';
const dashboardPort = '3001'; // Dashboard to use port 3001

/***
 * Gets Back end url depending on REACT_APP_STAGE env variable
 * @returns {string} Back end url 
 */
export const getBackendURL = () => {
  // development or testing
  if (!process.env.REACT_APP_STAGE || ['test', 'development'].includes(process.env.REACT_APP_STAGE))
    return `http://${window.location.hostname}:${port}`;
  // staging or produciton
  return `https://api.${window.location.host}`;
};

/***
 * Gets TeleEye MD hosts array depending on REACT_APP_STAGE env variable
 * @returns {string}  TeleEye MD hosts
 */
export const getTeleEyeHost = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'production': {
      return ['https://teleeyemd.telemedc.com', 'https://teleeyemd.telemedc.com.au', 'https://teleeyemd.telemedc.de'];
    }
    case 'staging': {
      return ['https://teleeye-dev.telemedc.com', 'https://teleeye-dev.telemedc.com.au', 'https://teleeye-staging.telemedc.de'];
    }
    case 'development': {
      return [`http://${window.location.hostname}:${window.location.port}`];
    }
    case 'test': {
      return [`http://${window.location.hostname}:${window.location.port}`];
    }
    default: {
      return [];
    }
  }
} 

/***
 * Determines if url contains trusted origin
 * @returns {boolean}  Valid url toggle
 */
export const validateUrlParameter = (url, trustedOrigins=[]) => {
  try {
    const newURL = new URL(url || '');
    return trustedOrigins?.includes(newURL.origin);
  } catch (error) {
    if (url) {
      console.error('Invalid URL:', url);
    }
    return false; // Handle null or invalid URL argument
  }
};

/***
 * Returns backend host depending on REACT_APP_STAGE env variable
 * @returns {string}  Backend host
 */
export const getBackendHost = () => {
  // development or testing
  if (!process.env.REACT_APP_STAGE || ['test', 'development'].includes(process.env.REACT_APP_STAGE))
    return `${window.location.hostname}:${port}`;
  // staging or production
  else return `api.${window.location.host}`;
};

/***
 * Returns image url depending on REACT_APP_STAGE env variable
 * @returns {string}  Image url
 */
export const getImageURL = (imageLink = '') => {
  imageLink = imageLink.replace(/^\/+/, '');
  if (imageLink.startsWith('http') || imageLink.startsWith('https')) {
    return imageLink;
  }
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
    case 'test':
      return `http://${window.location.hostname}:${port}/${imageLink}`;
    default:
      return `https://api.${window.location.host}/${imageLink}`;
  }
};

/***
 * Opens url in new tab
 * @param {string}  url Url to be opened
 */
export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noreferrer');
};

/***
 * Returns dashboard url depending on REACT_APP_STAGE env variable
 * @returns {string}  Dashboard url
 */
export const getDashboardURL = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'production':
      return 'https://www.dashboard.telemedc.com/';
    case 'staging':
      return 'https://www.staging.dashboard.telemedc.com/';
    case 'development':
      return `http://${window.location.hostname}:${dashboardPort}/`;
    case 'test':
      return `http://${window.location.hostname}:${dashboardPort}/`;
    default: {
      return '';
    }
  }
};
